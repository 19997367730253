import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/zhannum/git/the-dark-between/src/layouts/DefaultLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><a parentName="p" {...{
        "href": "https://discord.com/channels/623006378209968129/721129010649890867/919109058827534376"
      }}>{`https://discord.com/channels/623006378209968129/721129010649890867/919109058827534376`}</a></p>
    <p>{`Foundry Admin Password - `}<span>{`B93`}<inlineCode parentName="p">{`z9m$gcY=%irau`}</inlineCode>{`R3`}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      